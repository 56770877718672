<template>
    <div class="component-container white">
        <div class="card-list">
            <div v-for="(item, index) in list" :key="item.id" class="card">
                <BlogCard :content="item" v-if="contentType == 'blogArticles'" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardList',

    props: [
        'list',
        'contentType'
    ],

    components: {
        BlogCard: () => import('@/components/CardList/BlogCard.vue')
    },


}
</script>

<style lang="scss" scoped>
.component-container {

    .card-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start;
        max-width: $container-width;
        margin: 0 auto;
        padding: getSpacing('padding-default-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: getSpacing('padding-default-vertical', 'tablet') getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            padding: getSpacing('padding-default-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
        }

        .card {
            width: calc(50% - 10px);
            //margin: 10px;
            background-color: $lightGrey;
            overflow: hidden;

            @include breakpoint('mobile') {
                width: calc(100%);
            }
        }
    }
}
</style>
